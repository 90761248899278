import React, { useState, useEffect } from "react";

import isEmpty from "../../lib/isEmpty";
import { toastAlert } from "../../lib/toastAlert";
import { capitalize } from "../../lib/stringCase"

import validation from "./validation";

import { updateFundsProof } from "../../api/userKyc";
import { useDispatch, useSelector } from "react-redux";

const initialFormValue = {
    image: "",
};
let imageType = ['jpg', 'JPG', 'jpeg', 'JPEG', 'png', 'PNG', 'pdf', 'PDF']

const FundsProof = () => {

    const { fundsProof } = useSelector((state) => state.userKyc)


    // state
    const [formValue, setFormValue] = useState(initialFormValue);
    const [validateError, setValidateError] = useState({});
    const [loader, setLoader] = useState();
    const { image } = formValue;

    const dispatch = useDispatch()

    const handleFile = async (e) => {
        const { name, files } = e.target;
        if (!imageType.includes(e.target.files[0].type.split('/')[1])) {
            return toastAlert("error", 'Invalid image', "deposit");
        }
        let formData = { ...formValue, ...{ [name]: files[0] } };
        setFormValue(formData);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoader(true);
        try {
            // console.log(image, '-imageimage')


            // if (isEmpty(image)) {
            //     setValidateError({ ...validateError, ...{ ["image"]: "Image field is required" } });
            //     setLoader(false);
            //     return
            // }
            const formData = new FormData();
            formData.append("image", image);
            const { status, loading, message, errors } = await updateFundsProof(formData, dispatch);
            setLoader(loading);
            if (status == "success") {
                toastAlert("success", (message), "fundsProof");
            } else {
                if (errors) {
                    setValidateError(errors);
                    return;
                }
                toastAlert("error", (message), "fundsProof");
            }
        } catch (err) {
            console.log(err, '-----error on proof')
        }
    };



    return (
        <>
            <div className="asset_dashbox_flex_left">
                <h5 className="modal-title" id="kyc_modal">
                    Level 2 verification :  Proof of Funds
                </h5>
                <br />
                <h5>
                    Status: {" "} <span className={fundsProof.status == 'approved' ? 'text-success' : fundsProof.status != 'rejected' ? 'text-primary' : 'text-danger'}>{capitalize(fundsProof.status)}</span>
                </h5>
                {
                    fundsProof.status == 'rejected' &&
                    <span>Reason:{" "} {fundsProof.reason}</span>
                }
                <hr />
                <div class="user_info">
                    {(fundsProof.status == 'new' || fundsProof.status == 'rejected') &&
                        <form className="assets_form">

                            <div className="mb-3">
                                <p className="modal-title" id="kyc_modal">
                                    Image
                                </p>
                                <div className="mb-3">
                                    <div className="custom_file_upload btn">
                                        Upload
                                        <input type="file" name="image"
                                            onChange={handleFile}
                                        />
                                    </div>
                                    <div className="form-text ms-1">
                                        Max. upload file size 2MB (Jpg, Jpeg, Png, Pdf only).
                                    </div>
                                    <span className='text-danger'>{validateError?.image}</span>
                                </div>
                                {image?.name &&
                                    <div className="uploaded_files_row">
                                        <div>
                                            <i className="bi bi-file-earmark-plus"></i>
                                            <span>{image?.name}</span>
                                        </div>
                                        <div>
                                            <i className="bi bi-x-circle" onClick={() => setFormValue({ ...formValue, ...{ ['image']: "" } })}></i>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="asset_modal_btn_grp mt-4 mb-4">
                                <button
                                    className="w-100 primary_btn primary_btn_transform"
                                    type="button"
                                >
                                    Cancel
                                </button>
                                <button
                                    className="w-100 primary_btn primary_btn_with_bg primary_btn_transform"
                                    type="button"
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    }

                </div>
            </div>
            <div className="asset_dashbox_flex_right">
                <h3 className="asset_deposit_innertitle">For Level 2</h3>
                <ul className="deposit_notes_list">
                    <li>All assets unlocked for trading</li>
                    <li>Crypto withdrawal limit 10 BTC per day</li>
                    <li>Crypto Deposit limit unlimited per day</li>
                    <li>Hedge fund trading unlocked</li>
                </ul>
            </div>
        </>
    )
}


export default FundsProof;
