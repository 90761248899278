import React, { useState, useEffect } from "react";

import isEmpty from "../../lib/isEmpty";
import { toastAlert } from "../../lib/toastAlert";
import { capitalize } from "../../lib/stringCase"

import validation from "./validation";

import { updateIdProof } from "../../api/userKyc";
import { useDispatch, useSelector } from "react-redux";

const initialFormValue = {
    type: "",
    frontImage: "",
    backImage: "",
};

const IdProof = () => {

    const { idProof } = useSelector((state) => state.userKyc)

    // state
    const [formValue, setFormValue] = useState(initialFormValue);
    const [validateError, setValidateError] = useState({});
    const [loader, setLoader] = useState();
    const [front, setFront] = useState();
    const [back, setBack] = useState();
    const { type, frontImage, backImage } = formValue;

    const dispatch = useDispatch()

    // function
    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        let formData = { ...formValue, ...{ [name]: value } };
        setFormValue(formData);
        if (!isEmpty(validateError)) {
            setValidateError({});
        }
    };

    const handleFile = async (e) => {
        const { name, files } = e.target;

        let formData = { ...formValue, ...{ [name]: files[0] } };
        setFormValue(formData);
        if (name == "frontImage") {
            setFront(files[0].name);
        }
        if (name == "backImage") {
            setBack(files[0].name);
        }
        if (!isEmpty(validateError)) {
            setValidateError({});
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoader(true);
        let reqData = {
            type,
            frontImage,
            backImage,
        };

        let validationError = validation(reqData);
        if (!isEmpty(validationError)) {
            setValidateError(validationError);
            setLoader(false);
            return;
        }
        try {
            const formData = new FormData();
            formData.append("type", type);
            formData.append("frontImage", frontImage);
            formData.append("backImage", backImage);
            const { status, loading, message, errors } = await updateIdProof(formData, dispatch);
            setLoader(loading);
            if (status == "success") {
                toastAlert("success", (message), "idproof");
            } else {
                if (errors) {
                    setValidateError(errors);
                    return;
                }
                toastAlert("error", (message), "idproof");
            }
        } catch (err) { }
    };

    // console.log(idProof.status, '-----------idProof')

    return (
        <>
            <div className="asset_dashbox_flex_left">
                <h5 className="modal-title" id="kyc_modal">
                    Level 1 verification :{" "}  ID Proof
                </h5>
                <br />
                <h5>
                    Status: {" "} <span className={idProof.status == 'approved' ? 'text-success' : idProof.status != 'rejected' ? 'text-primary' : 'text-danger'}>{capitalize(idProof.status)}</span>
                </h5>
                {
                    idProof.status == 'rejected' &&
                    <span>Reason:{" "} {idProof.reason}</span>
                }
                <hr />
                <div class="user_info">
                    {(idProof.status == 'new' || idProof.status == 'rejected') &&

                        <form className="assets_form">

                            <div className="mb-3">
                                <label for="oldpassword" className="form-label">
                                    Document Type
                                </label>
                                <div className="asset_deposit_grp">
                                    <select name='type' value={type} onChange={handleChange}
                                        className="form-select primary_asset_select">
                                        <option value={""} >Select Document Type</option>
                                        <option value={"driving_license"} >Driving License</option>
                                        <option value={"passport"} >Passport</option>
                                        <option value={"government_issued_ID"} >Government issued ID</option>
                                    </select>
                                </div>
                                <span className='text-danger'>{validateError?.type}</span>
                            </div>

                            <div className="mb-3">
                                <p className="modal-title" id="kyc_modal">
                                    Front Image
                                </p>
                                <div className="mb-3">
                                    {/* <label for="withdrawamount" className="form-label">
                                Upload Document#7132f5
                            </label> */}
                                    <div className="custom_file_upload btn">
                                        Upload
                                        <input type="file" name="frontImage"
                                            onChange={handleFile}
                                        />
                                    </div>
                                    <div className="form-text ms-1">
                                        Max. upload file size 2MB (Jpg, Jpeg, Png, Pdf only).
                                    </div>
                                    <span className='text-danger'>{validateError?.frontImage}</span>
                                </div>
                                {frontImage?.name &&
                                    <div className="uploaded_files_row">
                                        <div>
                                            <i className="bi bi-file-earmark-plus"></i>
                                            <span>{frontImage?.name}</span>
                                        </div>
                                        <div>
                                            <i className="bi bi-x-circle" onClick={() => setFormValue({ ...formValue, ...{ ['frontImage']: "" } })}></i>
                                        </div>
                                    </div>
                                }
                            </div>


                            <div className="mb-3">
                                <label className="modal-title" id="kyc_modal">
                                    Back Image
                                </label>
                                <div className="mb-3">
                                    {/* <label for="withdrawamount" className="form-label">
                                Upload Document
                            </label> */}
                                    <div className="custom_file_upload btn">
                                        Upload
                                        <input type="file" name="backImage"
                                            onChange={handleFile}
                                        />

                                    </div>
                                    <div className="form-text ms-1">
                                        Max. upload file size 2MB (Jpg, Jpeg, Png, Pdf only).
                                    </div>
                                    <span className='text-danger'>{validateError?.backImage}</span>

                                </div>
                                {backImage?.name &&
                                    <div className="uploaded_files_row">
                                        <div>
                                            <i className="bi bi-file-earmark-plus"></i>
                                            <span>{backImage?.name}</span>
                                        </div>
                                        <div>
                                            <i className="bi bi-x-circle" onClick={() => setFormValue({ ...formValue, ...{ ['backImage']: "" } })}></i>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="asset_modal_btn_grp mt-4 mb-4">
                                <button
                                    className="w-100 primary_btn primary_btn_transform"
                                    type="button"
                                >
                                    Cancel
                                </button>
                                <button
                                    className="w-100 primary_btn primary_btn_with_bg primary_btn_transform"
                                    type="button"
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    }
                </div>
            </div>
            <div className="asset_dashbox_flex_right">
                <h3 className="asset_deposit_innertitle">For Level 1</h3>
                <ul className="deposit_notes_list">
                    <li>Staking crypto currency unlocked</li>
                    <li>Unlimited crypto trading</li>
                    <li>Crypto withdrawal limit 5 BTC per day</li>
                    <li>Crypto deposit limit 5 BTC per day</li>
                </ul>
            </div>



        </>
    )
}


export default IdProof;
